<template>
  <div class="main-view">
    <el-radio-group
      v-model="table.params.check_status"
      @change="onSearch"
      style="margin-bottom: 10px"
    >
      <el-radio-button :label="2">已通过{{table.pass_count}}</el-radio-button>
      <el-radio-button :label="1">待审核{{table.check_count}}</el-radio-button>
      <el-radio-button :label="3">已拒绝{{table.reject_count}}</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="企业名称" prop="name">
          <el-input clearable v-model.trim="table.params.name" placeholder="请输入" id="name" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input clearable v-model.trim="table.params.contact" placeholder="请输入" id="contact" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="业务范围" prop="kinds">
          <el-select v-model="table.params.kinds" filterable clearable placeholder="请选择" id="kinds" @focus="getClassify" @change="onSearch">
            <el-option
              v-for="item in classifyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运营状态" prop="status">
          <el-select v-model="table.params.status" filterable clearable placeholder="请选择" id="status" @change="onSearch">
            <el-option label="正常" :value="1"></el-option>
            <el-option label="冻结" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区" prop="city">
          <el-cascader
            :popper-append-to-body="false"
            popper-class="areaCascaderWrap"
            v-model="table.params.city"
            :options="areaArr"
            id="city"
            clearable
            filterable
            :props="{ emitPath:false }"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="toStr({1:field1,2:field2,3:field3},table.params.check_status)"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:province="{row}">
        <span>{{row.province_name}}-{{row.city_name}}</span>
      </template>
      <!-- 抽佣比例 -->
      <template v-slot:rate="{row}">
        <el-link type="primary" @click="handleRate(row)">{{ row.rate }} %</el-link>
      </template>
      <template v-slot:name="{row}">
        <div >
          {{row.name}}
          <span v-if="row.status == 2" class="lack_num">已冻结</span>
        </div>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" v-if="table.params.check_status == 1" @click="showDetail(row)">审核</el-button>
        <template v-else>
          <el-button type="text" icon="el-icon-view" @click="showDetail(row)">查看</el-button>
          <el-button v-if=" table.params.check_status == 2" type="text" @click="handleFreeze(row)">{{row.status == 1 ? '冻结' : '取消冻结'}}</el-button>
        </template>
      </template>
    </VTable>

    <!-- 冻结、修改抽佣比例 -->
    <el-dialog
      :title="type == 1 ? '冻结' : '修改抽佣比例'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      center>
      <el-form
        v-loading="form.data.loading"
        element-loading-text="加载中…"
        ref="elFormDom"
        :model="form.data"
        :rules="form.rules"
      >
        <!-- type == 1 冻结 -->
        <el-form-item prop="freeze_reason" label="" v-if="type == 1">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入100字内"
            id="freeze_reason"
            v-model="form.data.freeze_reason"
            maxlength="100">
          </el-input>
        </el-form-item>
        <!-- type == 2 修改抽佣比例 -->
        <el-form-item prop="rate" label="" v-if="type == 2">
          <el-input v-model="form.data.rate" placeholder="≤30" id="rate" clearable @input="handleInput">
          <span slot="suffix">%</span>
        </el-input>
        </el-form-item>
        <el-alert
          :title="type == 1 ? '提示：冻结后系统将不再给该机构派单' : '提示：修改后机构端新派订单将启用该比例'"
          type="warning"
          :closable="false">
        </el-alert>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="form.loading" @click="optionsFn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 创建 -->
    <edit ref="edit" :areaArr="areaArr" @refresh="getTable"></edit>
    <!-- 详情 -->
    <detail ref="detail" @refresh="getTable"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import Detail from './components/Detail.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'OrganizationList',
  components: {
    VTable,
    Edit,
    Detail,
  },
  mixins: [mixinTable],
  data() {
    return {
      field2: [
        { name: "name", label: "企业名称", width:'220', showTooltip: true, hidden: false },
        { name: "simple_name", label: "企业简称", hidden: false },
        { name: "xy_code", label: "企业信用代码", width:'180', hidden: false },
        { name: "contact", label: "联系人", hidden: false },
        { name: "phone", label: "联系电话",width:'120', hidden: false },
        { name: "province", label: "所在地区",width:'160',showTooltip: true, hidden: false },
        { name: "kind_str", label: "业务范围", width: '180', showTooltip: true, hidden: false },
        { name: "count", label: "派单数量", hidden: false },
        { name: "score", label: "综合评分", hidden: false },
        { name: "rate", label: "抽佣比例", hidden: false },
        { name: "pass_at", label: "入驻时间", width:'160', hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "140", hidden: false }
      ],
      field1: [
        { name: "name", label: "企业名称", width:'220', showTooltip: true, hidden: false },
        { name: "simple_name", label: "企业简称", hidden: false },
        { name: "xy_code", label: "企业信用代码", width:'180', hidden: false },
        { name: "contact", label: "联系人", hidden: false },
        { name: "phone", label: "联系电话",width:'120', hidden: false },
        { name: "province", label: "所在地区",width:'160',showTooltip: true, hidden: false },
        { name: "kind_str", label: "业务范围", width: '180', showTooltip: true, hidden: false },
        { name: "commit_at", label: "申请时间", width:'160', hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "120", hidden: false }
      ],
      field3: [
        { name: "name", label: "企业名称", width:'220', showTooltip: true, hidden: false },
        { name: "simple_name", label: "企业简称", hidden: false },
        { name: "xy_code", label: "企业信用代码", width:'160', hidden: false },
        { name: "contact", label: "联系人", hidden: false },
        { name: "phone", label: "联系电话",width:'120', hidden: false },
        { name: "kind_str", label: "业务范围", width: '180', showTooltip: true, hidden: false },
        { name: "reject_at", label: "拒绝时间", width:'160', hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          check_status: 2, //审核状态：1-待审核；2-审核成功；3-审核失败；
          status: '', //运营状态：1-正常；2-冻结
          name: '', // 企业名称
          contact: '',// 联系人
          city: '',
          kinds:'', // 工种
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        pass_count: 0, //通过总数
        check_count: 0, //待审核总数
        reject_count: 0 //已拒绝总数
      },
      dialogVisible: false,
      form: {
        loading: false,
        data: {
          id: '',
          freeze_reason: '',//冻结原因，当为冻结操作时该值必传
          rate: '', // 抽佣比例
        },
        freeze_reason:[{ required: true, message:'请输入冻结原因', trigger: 'blur' }],
        rate:[{ required: true, message:'请输入抽佣比例', trigger: 'blur' }],
      },
      type: 1,
      areaArr: [],
      classifyOpt: [],
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      let _params = { ...this.table.params }
      if(!!_params.area_arr) {
        _params.province = _params.area_arr[0];
        _params.city = _params.area_arr[1];
      }
      delete _params.area_arr;
      this.$http.get('/admin/flex_organization/list', { params: _params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.pass_count = res.data.pass_count; //通过总数
          this.table.check_count = res.data.check_count; //待审核总数
          this.table.reject_count = res.data.reject_count; //已拒绝总数
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取一级分类
    getClassify() {
      this.$http.get('/admin/flex_kind/list', { params:{page:1,count:1000,type:1}}).then(res => {
        if(res.code === 1) {
          this.classifyOpt= res.data.list;
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 查看、审核
    showDetail(row) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 禁用启用
    handleFreeze(row) {
      this.type = 1;
      this.form.data.id = row.id;
      // 运营状态：1-正常；2-冻结
      if(row.status == 1) {
        this.dialogVisible = true;
      } else {
        this.$confirm(`请确认取消解冻操作？`, "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.optionsFn();
        }).catch(() => {})
      }
    },
    optionsFn() {
      if(this.type == 2 && !this.form.data.rate) {
        this.$message.warning("请设置机构抽佣比例")
        return
      } else if(this.type == 1 && this.dialogVisible == true && !this.form.data.freeze_reason) {
        this.$message.warning("请输入冻结原因")
        return
      }
      let _params = {
        id: this.form.data.id,
        freeze_reason: this.form.data.freeze_reason, // 禁用原因
        rate: this.form.data.rate, // 抽佣比例
      }
      // type: 1: 禁用/启用， 2：设置抽佣比例
      let apiurl = this.type == 1 ? '/admin/flex_organization/operation' : '/admin/flex_organization/changeRate'
      this.form.loading = true;
      this.$http.post(apiurl, _params).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
          this.dialogVisible = false;
          // 清空form对象属性值
          Object.keys(this.form.data).forEach(item => {
            this.form.data[item] = '';
          })
        } else {
          this.$message.error(res.data)
        }
      }).finally(() => {
        this.form.loading = false;
      })
    },
    // 修改抽佣比例
    handleRate(row) {
      this.form.data.id = row.id;
      this.type = 2;
      this.dialogVisible = true;
    },
    handleInput() {
      const regex = /^([1-2]?[0-9]|30)?$/;
      if (!regex.test(this.form.data.rate)) {
        // 如果输入的数字不在0到30之间，则将输入值重置为上一次的值
        this.form.data.rate = this.form.data.rate.substring(0, this.form.data.rate.length - 1);
      }
    }
  }
}
</script>
<style scoped>
.lack_num {
  background: #e6f1fd;
  color: #0376e7;
  width: 50px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}
</style>