<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-form
        v-loading="loading"
        element-loading-text="上传中…"
        ref='elFormDom'
        label-width='110px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="营业执照" prop="license">
                <el-upload
                  class="avatar-uploader-login"
                  action=""
                  :headers="{ token: token }"
                  :show-file-list="false"
                  id="license"
                  :before-upload="beforeAvatarUpload"
                  :http-request="httpRequest">
                  <img v-if="form.data.license" :src="form.data.license" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <!-- <div class="el-upload__tip" slot="tip">建议尺寸：100*100像素</div> -->
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="门头照" prop="door">
                <el-upload
                  class="avatar-uploader-login"
                  action=""
                  :headers="{ token: token }"
                  :show-file-list="false"
                  id="door"
                  :before-upload="beforeAvatarUpload"
                  :http-request="httpRequest2">
                  <img v-if="form.data.door" :src="form.data.door" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <!-- <div class="el-upload__tip" slot="tip">建议尺寸：100*100像素</div> -->
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item prop='name' label='企业名称'>
                <el-input v-model="form.data.name" placeholder="上传营业执照识别" id="name" clearable :disabled="true"></el-input>
              </el-form-item> 
            </el-col>
            <el-col :span="12">
              <el-form-item prop='simple_name' label='企业简称'>
                <el-input v-model="form.data.simple_name" placeholder="请输入" id="simple_name" clearable ></el-input>
              </el-form-item> 
            </el-col>
            <el-col :span="12">
              <el-form-item prop='xy_code' label='企业信用代码'>
                <el-input v-model="form.data.xy_code" placeholder="上传营业执照识别" id="xy_code" clearable :disabled="true"></el-input>
              </el-form-item> 
            </el-col>
            <el-col :span="12">
              <el-form-item prop='working_address' label='经营地址'>
                <el-input v-if="!!form.data.working_address" v-model="form.data.working_address" placeholder="请选择" id="working_address" clearable></el-input>
                <el-button v-else size="mini" type="primary" plain icon="el-icon-location-information" @click="handleMap">打开地图</el-button>
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item prop='kind_ids' label='业务范围'>
                <el-select v-model="form.data.kind_ids" filterable multiple clearable placeholder="请选择" id="kind_ids">
                  <el-option
                    v-for="item in classifyArr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item> 
            </el-col>
            <el-col :span="12">
              <el-form-item prop='address' label='详细地址'>
                <el-input v-model="form.data.address" placeholder="楼栋门牌号" id="address" clearable></el-input>
              </el-form-item> 
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item prop='contact' label='联系人'>
                <el-input v-model="form.data.contact" placeholder="请输入" id="contact" clearable></el-input>
              </el-form-item> 
            </el-col>
            <el-col :span="12">
              <el-form-item prop='phone' label='联系电话'>
                <el-input v-model="form.data.phone" maxlength="11" placeholder="请输入" id="phone" clearable></el-input>
              </el-form-item> 
            </el-col>
            <el-col :span="12">
              <el-form-item prop='account' label='登录账号'>
                <el-input v-model="form.data.account" placeholder="请输入" id="account" clearable></el-input>
              </el-form-item> 
            </el-col>
            <el-col :span="12">
              <el-form-item prop='rate' label='抽佣比例'>
                <el-input v-model="form.data.rate" placeholder="≤30" id="rate" clearable @input="handleInput">
                  <span slot="suffix">%</span>
                </el-input>
              </el-form-item> 
            </el-col>
          </el-row>
        <el-row type="flex" justify="center">
          <el-button @click='toggle(false)'>取消</el-button>
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
        </el-row>
      </el-form>
    </div>

    <!-- 地图 -->
    <platmap ref="platmap" @refresh="getPlatmap"></platmap>

  </div>
</template>

<script>
  import platmap from './platmap.vue'
  import common from '@/util'
  export default {
    name: 'AreaEdit',
    props: ['areaArr'],
    components: {
      platmap
    },
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            kind_ids: [], //业务范围 214,199,101
            name: "", //公司名称
            simple_name: "", //企业简称
            xy_code: "", //企业信用代码
            working_address: "", //经营地址
            longitude: "", //经度
            latitude: "", //纬度
            address: "", //详细地址
            license: "", //营业执照
            door: "", //门头照
            contact: "", //联系人
            phone: "", //联系电话
            account: "", //账号
            rate: '', // 折扣率
          },
          rules: {
            license: [{ required: true, message:'请上传营业执照', trigger: 'change' }],
            door: [{ required: true, message: '请上传门头照', trigger: 'change' }],
            name: [{ required: true, message: '必填项', trigger: 'change' }],
            xy_code: [{ required: true, message: '必填项', trigger: 'change' }],
            simple_name: [{ required: true, message: '必填项', trigger: 'change' }],
            working_address: [{ required: true, message: '必填项', trigger: 'change' }],
            kind_ids: [{ required: true, message: '必填项', trigger: 'change' }],
            contact: [{ required: true, message: '必填项', trigger: 'change' }],
            phone: [{ required: true, message: '必填项', trigger: 'change' }],
            account: [{ required: true, message: '必填项', trigger: 'change' }],
            rate: [{ required: true, message: '必填项', trigger: 'change' }],
          }
        },
        classifyArr: [], // 启用中的一级分类
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.getClassify();
        if(!!row.id) {
          this.isChange = true;
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            if(row.area == 0) {
              this.form.data.area_arr = [row.province,row.city]
            } else {
              this.form.data.area_arr = [row.province,row.city,row.area]
            }
          })
        }
      },
      // 获取一级分类
      getClassify() {
        this.$http.get('/admin/flex_kind/list', { params:{page:1,count:1000,type:1}}).then(res => {
          if(res.code === 1) {
            this.classifyArr = res.data.list.filter(item => item.status == 1)
          }
        })
      },
      getPlatmap(val) {
        this.form.data.longitude = val.longitude; // 经度
        this.form.data.latitude = val.latitude; // 经度
        this.form.data.working_address = val.working_address; // 经营地址
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      // 上传主图
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.loading = true;
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.$refs.elFormDom.clearValidate();
          this.form.data.license = res.data.all_path_url;
          this.$http.post('/common/baidu/license',{url:res.data.all_path_url}).then(res => {
            if(res.code == 1) {
              console.log(res.data, "11")
              this.form.data.name = res.data.name;
              this.form.data.xy_code = res.data.credit_code;
            }
          }).finally(() => {
            this.loading = false;
          })
        })
      },
      // 上传门头照
      httpRequest2(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.loading = true;
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.$refs.elFormDom.clearValidate();
          this.form.data.door = res.data.all_path_url;
        }).finally(() => {
          this.loading = false;
        })
      },
      handleInput() {
        const regex = /^([1-2]?[0-9]|30)?$/;
        if (!regex.test(this.form.data.rate)) {
          // 如果输入的数字不在0到30之间，则将输入值重置为上一次的值
          this.form.data.rate = this.form.data.rate.substring(0, this.form.data.rate.length - 1);
        }
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if(valid) {
            let _params = {
              ...this.form.data
            }
            _params.kind_ids = this.form.data.kind_ids.join(",");
            console.log(_params)
            this.form.loading = true;
            this.$http.post('/admin/flex_organization/add', _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$message.success("操作成功！")
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      handleMap() {
        let dom = this.$refs.platmap;
        dom.toggle(true)
        dom.getDetail()
        dom = null
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;
    // text-align: center;

    .info-top{
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .time {
      font-size: 18px;
      color: #8C8C8C;
      margin-bottom: 20px;
    }
  }
  .avatar-uploader-login .el-icon-plus {
    width: 100px;
    height: 100px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
  }
  .avatar-uploader-login .avatar-uploader-icon{
    line-height: 100px !important;
  }
  .avatar-uploader-login .avatar {
    width: 100px;
    height: 100px;
  }
  
}
</style>
