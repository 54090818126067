<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:1.25rem;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <div style="text-align:left;" v-if="info.check_status == 1 || info.check_status == 2">最近登录时间：{{info.last_login_time}}</div>
      <div style="text-align:left;" v-if="info.check_status == 3">拒绝原因： {{info.reject_reason}}</div>
      <el-descriptions title="" :column="2" border style="margin-top:1.25rem;">
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="企业名称">{{ info.name }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="企业信用代码">{{ info.xy_code }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="企业简称">{{ info.simple_name }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="经营地址">{{ info.working_address }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="业务范围">{{ info.kind_str }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="登录账号">{{ info.account }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="联系人">{{ info.contact }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="联系电话">{{ info.phone }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="派单数量" v-if="info.check_status == 2">{{ info.count }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="综合评分" v-if="info.check_status == 2">{{ info.score }}分</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="申请时间"> {{ info.commit_at }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="审核时间"> {{ info.pass_at }}</el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="营业执照">
          <el-image
            style="width: 3.75rem; height: 3.75rem"
            :src="info.license"
            fit="cover"
            :preview-src-list="[info.license]">
              <div slot="error" class="image-slot"></div>
            </el-image>
        </el-descriptions-item>
        <el-descriptions-item :labelStyle='labelStyle' :contentStyle='contentStyle' label="门头照">
          <el-image
            style="width: 3.75rem; height: 3.75rem"
            :src="info.door"
            fit="cover"
            :preview-src-list="[info.door]">
              <div slot="error" class="image-slot"></div>
            </el-image>
        </el-descriptions-item>
      </el-descriptions>
      <!-- 待审核状态展示审核操作按钮 -->
      <el-row type="flex" justify="center" style="margin-top:1.25rem;" v-if="check_status == 1">
        <el-button type="danger" plain @click='verify(2)'>审核拒绝</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='verify(1)'>审核通过</el-button>
      </el-row>

      <!-- 已通过查看详情：企业信息下方增加门店人员列表 ,审核状态：1-待审核；2-审核成功；3-审核失败；-->
      <div style="text-align:left;margin-top:20px;" v-if="check_status == 2">
        <div style="margin:10px 0">门店人员（{{info.sub_account_total}}） <el-button size="mini" type="primary" plain  @click="handleAdd">添加账号</el-button></div>
        <el-table
          :data="tableData"
          border
          size="mini"
          :header-cell-style="{background:'#f5f5f5'}"
          style="width: 100%">
          <el-table-column
            prop=""
            type="index"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            prop="nickname"
            label="姓名">
          </el-table-column>
          <el-table-column
            prop="account"
            label="账号">
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="添加时间">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="{row,$index}">
              <span v-if="$index == 0">超级管理员</span>
              <el-button v-else type="text" size="small" @click="remove(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 审核 -->
    <el-dialog
      :title="form.data.type == 1 ? '审核通过' : '审核拒绝'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
      width="31.25rem"
      center>
      <el-form
        v-loading="form.data.loading"
        element-loading-text="加载中…"
        ref="elFormDom"
        :model="form.data"
        :rules="form.rules"
      >
        <!-- 审核通过设置抽佣比例 -->
        <template v-if="form.data.type == 1">
          <el-form-item prop="rate" label="机构抽佣" >
            <el-input v-model="form.data.rate" placeholder="≤30" clearable @input="handleInput">
            <span slot="suffix">%</span>
          </el-input>
          </el-form-item>
          <el-alert
            :title="'提示：机构将抽取师傅所得金额对应比例，并非按客户金额抽取比例'"
            type="warning"
            :closable="false">
          </el-alert>
        </template>
        <!-- type == 2 拒绝 -->
        <el-form-item prop="reject_reason" label="" v-if="form.data.type == 2">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入100字内"
            v-model="form.data.reject_reason"
            maxlength="100">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="form.loading" @click="optionsFn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加子账号 -->
    <el-dialog
      title="添加子账号"
      :visible.sync="dialogVisibleForm"
      width="450px"
      :before-close="handleClose"
      append-to-body
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form
        ref="elFormDomRule"
        label-width="100px"
        label-position="right"
        :model="ruleForm.data"
        :rules="ruleForm.rules"
        size="small"
      >
        <el-form-item prop="name" label="姓名：">
          <el-input
            v-model="ruleForm.data.name"
            clearable
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="account" label="账号：">
          <el-input
            v-model="ruleForm.data.account"
            maxlength="11"
            clearable
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button
            type="primary"
            :loading="ruleForm.loading"
            :disabled="ruleForm.loading"
            @click="submitForm"
            >确定</el-button
          >
        </el-row>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'OrganizationDetail',
  data() {
    return {
      visible: false,
      tableData: [],
      info: {
        worker: {},
        sub_account_total: 0,
      },
      check_status: 1,
      labelStyle: { width: '6.25rem' },
      contentStyle: { width: '18.75rem' },
      form: {
        loading: false,
        data: {
          id: '',
          type: '',
          is_pass: '',
          reject_reason: '',
          rate: '',
        }
      },
      dialogVisible: false,
      dialogVisibleForm: false,
      ruleForm: {
        loading: false,
        data: {
          organization_id: '', //机构id
          name: '',
          account: '',
        },
        rules: {
          name: [{ required: true, message:'请输入姓名', trigger: 'change' }],
          account: [{ required: true, message:'请输入账号', trigger: 'change' }],
        }
      },
      currRow: {},
    }
  },
  methods: {
    // 获取详情
    getDetail(row) {
      this.currRow = row;
      this.isChange = true
      this.$http.get('/admin/flex_organization/detail', {params:{id: row.id} }).then(res => {
        if(res.code === 1) {
          this.info = res.data;
          this.tableData = res.data.sub_accounts; //门店人员列表
          this.check_status = res.data.check_status; //审核状态：1-待审核；2-审核成功；3-审核失败；
          let index = res.data.working_address.indexOf('-'); // 经营地址中查找第一个横线出现的位置（没有则返回-1）
          let originalStr  =  res.data.working_address; // 经营地址
          let charToInsert = res.data.address; // 详细地址
          // 判断如果经营地址中有 "-",就把详细地址拼接在第一个横线前面。如果经营地址中没有 "-",就把详细地址拼在经营地址后面
          if(index > 0) {
            this.info.working_address = this.insertChar(originalStr, index, charToInsert);
          } else {
            this.info.working_address = res.data.working_address + res.data.address; 
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    insertChar(str, index, char) {
      return str.substring(0, index) + char + str.substring(index);
    },
    verify(type) {
      this.form.data.type = type; // 1通过，2拒绝
      if(this.info.status == 0 || type == 2 ) { // 审核通过过，修改后再审核不需要弹窗抽佣比例设置
        this.dialogVisible = true;
      } else {
        this.$confirm('请确认审核通过操作？','提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.form.data.rate = this.info.rate; // 抽佣比例赋默认值
          this.optionsFn();
        }).catch(() => {})
      }
    },
    // 审核操作
    optionsFn() {
      if(this.form.data.type == 1 && !this.form.data.rate) {
        this.$message.warning("请设置机构抽佣比例")
        return
      } else if(this.form.data.type == 2 && !this.form.data.reject_reason) {
        this.$message.warning("请输入拒绝原因")
        return
      }
      let _params = {
        id: this.info.id,
        is_pass: this.form.data.type, //是否通过：1-是；2-否
        reject_reason: this.form.data.reject_reason, // 拒绝原因
        rate: this.form.data.rate, //抽佣，最大30%
      }
      this.form.loading = true;
      this.$http.post('/admin/flex_organization/check', _params).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.dialogVisible = false;
          this.toggle(false)
          this.$emit('refresh')
          // 清空form对象属性值
          Object.keys(this.form.data).forEach(item => {
            this.form.data[item] = '';
          })
        } else {
          this.$message.error(res.data)
        }
      }).finally(() => {
        this.form.loading = false;
      })
    },
    handleInput() {
      const regex = /^([1-2]?[0-9]|30)?$/;
      if (!regex.test(this.form.data.rate)) {
        // 如果输入的数字不在0到30之间，则将输入值重置为上一次的值
        this.form.data.rate = this.form.data.rate.substring(0, this.form.data.rate.length - 1);
      }
    },
    reset(done) {
      this.isChange = false
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    toStr(data, key) {
      return data[key]
    },
    handleAdd() {
      this.dialogVisibleForm = true;
    },
    submitForm() {
      this.$refs.elFormDomRule.validate(valid => {
        if (valid) {
          this.ruleForm.data.organization_id = this.currRow.id; // 机构id
          this.ruleForm.loading = true;
          this.$http.post('/admin/flex_organization/createSubAccount', this.ruleForm.data).then(res => {
            if(res.code === 1) {
              this.$message.success("操作成功")
              this.getDetail(this.currRow) // 刷新详情
              this.handleClose(); // 关闭弹窗
              this.ruleForm.data.name = '';
              this.ruleForm.data.account = '';
            } else {
              this.$message.error(res.msg)
            }
          }).finally(() => {
            this.ruleForm.loading = false;
          })
        }
      })
    },
    // 删除门店人员
    remove(row) {
      this.$confirm('请确认删除操作','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/admin/flex_organization/deleteSubAccount",{id:row.id}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功')
            this.getDetail(this.currRow)
          }
        })
      }).catch(() => {})
    },
    handleClose() {
      this.dialogVisibleForm = false;
      this.$refs.elFormDomRule.resetFields()
      Object.keys(this.ruleForm.data).forEach(item => {
        this.ruleForm[item] = ''
      })
    },
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: .625rem;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
}
.avatar_img ::v-deep img {
  width: 100%;
  height: 100%;
}

</style>

